import React, { useEffect, useState } from "react";
import { Col, Modal } from "reactstrap";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { Filtros } from "./filtros";
import { baseUrlApi } from "../../../api/baseUrl";

export const Visualizar = ({ calculo, handleOperadora, handleVisualizarCotacoes, ramo, handleClickOperadoraSelect, handleClickOperadoraDeselect, loadingDetalhes, valores, infoDependente, handleVisualizarCotacoesTodas, setCalculoFiltro, calculoFiltro, handleClickOperadoraSelectAll, handleClickOperadoraDeselectAll, traducaoCobertura, traducaoCoparticipacao, traducaoContratacao, traducaoReembolso, coberturaList, municipioList, coparticipacaoList, acomodacaoList, contratacaoList, reembolsoList, setMunicipio, setCobertura, setCoparticipacao, setAcomodacao, setContratacao, setReembolso, calculoDetalhe, contratacao, reembolso,
  cobertura, municipio, coparticipacao, acomodacao, modalFiltro, setModalFiltro, visualizar,setVisualizar }) => {

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);


  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (


    <div className="" style={{ marginTop: 100 }}>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%', flexDirection: isDesktop ? 'row' : 'column' }}>

        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <p style={{ fontWeight: 100 }}>Cálculo Nº {calculoDetalhe?.id}</p>
          <p style={{ fontWeight: 100 }}>Vidas: {calculoDetalhe?.vidas}</p>
          <p style={{ fontWeight: 100, width: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
            {`${calculoDetalhe?.nome ? calculoDetalhe.nome : ''}`}
          </p>
        </div>
        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', height: '100%' }}>
          <p style={{ fontWeight: 100 }}>
            {valores?.valorMenor?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
            {' '}Menor Preço ({valores?.operadoraMenor})
          </p>
          <p style={{ fontWeight: 100 }}>
            {valores?.valorIntermediario?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
            {' '}Preço Intermediário (
            {valores?.operadoraIntermediario})
          </p>
          <p style={{ fontWeight: 100 }}>
            {valores?.valorMaior?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            })}
            {' '}Maior Preço ({valores?.operadoraMaior})
          </p>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', marginTop: 15 }}>

        <div style={{ width: 600, display: modalFiltro ? 'flex' : 'none' }}>
          <Filtros calculo={calculo} setCalculoFiltro={setCalculoFiltro} traducaoCobertura={traducaoCobertura} traducaoCoparticipacao={traducaoCoparticipacao} traducaoContratacao={traducaoContratacao} traducaoReembolso={traducaoReembolso} coberturaList={coberturaList} municipioList={municipioList} coparticipacaoList={coparticipacaoList} acomodacaoList={acomodacaoList} contratacaoList={contratacaoList} reembolsoList={reembolsoList} setMunicipio={setMunicipio} setCobertura={setCobertura} setCoparticipacao={setCoparticipacao} setAcomodacao={setAcomodacao} setContratacao={setContratacao} setReembolso={setReembolso} calculoDetalhe={calculoDetalhe}
            cobertura={cobertura} municipio={municipio} coparticipacao={coparticipacao} acomodacao={acomodacao} contratacao={contratacao} reembolso={reembolso}
          />
        </div>


      </div>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', marginBottom: 15 }}>
        <button className="btn btn-primary" style={{ width: 200, marginRight: 10, fontWeight: 300 }}
          onClick={() => {
            handleVisualizarCotacoesTodas();
          }} >Total: <span style={{ marginLeft: 4, marginRight: 4 }}>{calculoFiltro.filter((fill) => fill.status == 1).reduce((acumulador, element) => { return acumulador + element.tabelas.length }, 0)}</span>  Cotações </button>
        <button className="btn btn-primary" style={{ width: 200, marginRight: 10, width: 200, marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={() => {
            setModalFiltro(!modalFiltro);
          }} > Filtrar Cotações {modalFiltro ? <RiArrowDropUpLine size={22} style={{ paddingTop: 4 }} /> : <RiArrowDropDownLine size={22} style={{ paddingTop: 4 }} />}   </button>
        <button className="btn btn-primary" onClick={handleVisualizarCotacoes} disabled={loadingDetalhes} style={{ width: 200 }} >
          {loadingDetalhes ? "Carregando..." : "Visualizar Cotações"} </button>
      </div>

      <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: isDesktop ? "flex-start" : "center", flexDirection: 'column', marginLeft: isDesktop ? 60 : 0 }}>
        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row' }}>
          <input type="checkbox" defaultChecked={true} className="form-control" onChange={(e) => {
            if (e.target.checked) {
              setVisualizar(calculoFiltro.map(() => { return true }))
              handleClickOperadoraSelectAll()
            } else {
              setVisualizar(calculoFiltro.map(() => { return false }))
              handleClickOperadoraDeselectAll()
            }
          }} />
          <label style={{ marginBottom: 10, textAlign: 'center', marginLeft: 5 }} >Exibir Todas</label>
        </div>
      </div>
      {calculoFiltro.length > 0 &&
        calculoFiltro?.sort((a, b) => a?.status - b?.status || a?.ordemcotacao - b?.ordemcotacao)?.map((item, index) => (
          <div key={'Item' + item.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 4, backgroundColor: '#AAD2F5', flexDirection: isDesktop ? 'row' : 'column-reverse', padding: 10, borderRadius: 4 }}>

            <div style={{ display: 'flex', justifyContent: isDesktop ? 'flex-start' : 'center', alignItems: 'center', flex: 1, paddingTop: '10px', paddingLeft: isDesktop ? 50 : 0 }} >
              {item.status == 3 ? (
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row' }}>
                  <input type="checkbox" className="form-control" disabled />
                  <label style={{ marginBottom: 10, marginLeft: 5 }}>Exibir</label>
                </div>
              ) : (
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'row' }}>

                  <input type="checkbox" checked={visualizar[index]} className="form-control" onChange={(e) => {
                    let find = [...visualizar]
                    if (e.target.checked) {
                      find[index] = true
                      setVisualizar(find)
                      handleClickOperadoraSelect(item)
                    } else {
                      find[index] = false
                      setVisualizar(find)
                      handleClickOperadoraDeselect(item)
                    }

                  }} />
                  <label style={{ marginBottom: 10, marginLeft: 5 }}>Exibir</label>
                </div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }} >
              {item.status == 3 ? (
                <>
                  <button className="btn btn-danger" style={{ backgroundColor: '#C82333' }}>
                    Em atualização
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-primary"

                    onClick={() => {

                      handleOperadora(item);
                    }}
                  >
                    Ver {item?.tabelas?.length} {item?.tabelas?.length > 1 ? 'cotações' : 'cotação'}
                  </button>
                </>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img src={`${baseUrlApi}/imagens/${item.imagem}`} alt="" width={'100px'} />

                <p style={{ textAlign: "center", fontSize: 16 }}> {item.nome}  </p>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'justify', flex: 1 }}>
              <a className="btn btn-link" style={{ fontWeight: 100, color: '#092140' }} >
                Menor Preço: {item?.tabelas?.map((item2) => item2?.tabelafaixas?.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).filter((item3) => item3.valor).reduce((acumulador, element) => {
                  return acumulador + (parseFloat(element.valor?.replace(',', '.')) *
                    ((infoDependente?.length > 0) ? infoDependente?.map((elemento) => (elemento <= element?.faixa?.max && elemento >= element?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : calculoDetalhe.vidas)
                  )
                }, 0)).sort((a, b) => a - b)[0]?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <br />

                Maior Preço: {item?.tabelas?.map((item2) => item2?.tabelafaixas?.filter((item) => item.idfaixa != 1 ? 'cotacoesfaixa' in item ? item.cotacoesfaixa?.valor : item?.faixa?.nome : true).filter((item3) => item3.valor).reduce((acumulador, element) => {
                  return acumulador + (parseFloat(element.valor?.replace(',', '.')) *
                    ((infoDependente?.length > 0) ? infoDependente?.map((elemento) => (elemento <= element?.faixa?.max && elemento >= element?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : calculoDetalhe.vidas)
                  )
                }, 0)).sort((a, b) => a - b)[item?.tabelas.map((item2) => item2?.tabelafaixas.filter((item3) => item3.idfaixa != 1 ? 'cotacoesfaixa' in item3 ? item3.cotacoesfaixa?.valor : item3?.faixa?.nome : true).reduce((acumulador, element) => {
                  return acumulador + (parseFloat(element.valor?.replace(',', '.')) *
                    ((infoDependente?.length > 0) ? infoDependente?.map((elemento) => (elemento <= element?.faixa?.max && elemento >= element?.faixa?.min) ? 1 : 0).reduce((acc, val) => acc + val, 0) : calculoDetalhe.vidas)
                  )
                }
                  , 0)).sort((a, b) => a - b).length - 1]?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}


              </a>
            </div>

          </div>
        ))}

    </div>
  );
};
