import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row
} from "reactstrap";

import { useCookies } from "react-cookie";
// import images
import { Api } from "../../../api/apiUsuario";
import { ExitModal } from "../../components/exitModal";
import { getCookie, saveCookie } from "../../../components/cookies";

const CodigoModal = ({ modal, setModal, login }) => {
  const [cookies, setCookie] = useCookies(['authUser', 'time', 'authPagehist']);
  const [usuario, setUsuario] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [codigo1, setCodigo1] = useState("");
  const [codigo2, setCodigo2] = useState("");
  const [codigo3, setCodigo3] = useState("");
  const [codigo4, setCodigo4] = useState("");
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const [tokenEnviado, setTokenEnviado] = useState(false)
  const [time, setTime] = useState(30)
  const [start, setStart] = useState(false)
  const tempoPrincipal = cookies.time
  function handleInputChange(e) {
    const maxLength = e.target.maxLength;
    const currentLength = e.target.value.length;
    if (currentLength >= maxLength) {
      e.preventDefault();
      if (e.target === input1Ref.current) {
        input2Ref.current.focus();
      } else if (e.target === input2Ref.current) {
        input3Ref.current.focus();
      } else if (e.target === input3Ref.current) {
        input4Ref.current.focus();
      } else if (e.target === input4Ref.current) {
      }
    }
  }
  const handleTime = () => {
    let tempo1 = 1
    let timer = setInterval(() => {
      let tempo = 0
      if (tempoPrincipal > 0) {
        setTime(tempoPrincipal)
        tempo = tempoPrincipal - tempo1
        tempo1 = tempo1 + 1
        setTime(tempo)
        setCookie('time', JSON.stringify(tempo), { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
      } else {
        tempo = time - tempo1
        tempo1 = tempo1 + 1
        setTime(tempo)
        setCookie('time', JSON.stringify(tempo), { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
      }
      if (tempo == 0) {
        setStart(false)
        clearInterval(timer)
      }
    }, 1000)

  }
  useEffect(() => {
    if (start) {
      handleTime()
    }
  }, [start])
  useEffect(() => {
    setUsuario(login)
  }, [login])
  const handleEnviarToken = async () => {
    setLoading(true);
    if (!start && modal) {
      let response = await Api.dispararCodigo({ usuario });
      if (response.error) {
        setMensagem(enviar.message);
        setError(true);
      } else {
        setTokenEnviado(true)
        if (!start) {
          setTime(30)
          setCookie('time', JSON.stringify(0),);
          setStart(true)
        }
        setMensagem(response.message);
        setError(false);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (codigo1 && codigo2 && codigo3 && codigo4) {
      handleValidar()
    }
  }, [codigo1, codigo2, codigo3, codigo4])
  const handleValidar = async () => {
    setLoading(true);
    if (codigo1 && codigo2 && codigo3 && codigo4) {
      let response = await Api.login({ email: usuario, codigo: `${codigo1}${codigo2}${codigo3}${codigo4}` });
      if (response?.error) {
        setMensagem(response?.message);
        setError(true);
      } else {
        const { token, user } = response
        const { permissoes, ...dados } = user
        saveCookie('authToken', token)
        saveCookie('authUser', { ...dados })
        let page = getCookie('authPagehist')
        navigate((!page?.includes('/') ? page : '/ferramentas'))
        // window.location.href = ((page && page != 'null') ? page : '/')
      }
    }
    setLoading(false);
  };
  document.title = "Corretor Parceiro";
  return (
    <>
      <Modal id="modal-center3" size="md" centered={true} isOpen={modal}>
        <div className="modal-header" style={{ border: 'none' }} >
          <ExitModal setModal={setModal} />

        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30 }} >

          <div className="auth-page">
            <div className="text-center">
              <p className="" style={{ color: '#092140', fontSize: 20 }}>
                Código de Acesso
              </p>
            </div>
            <Container fluid className="p-0">
              <Row className="g-0">
                <Col lg={12} md={12} className="col-xxl-12">
                  <div className="auth-full-page-content d-flex p-sm-2 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="auth-content my-auto">
                          <Alert color="success" style={{ marginTop: "13px", textAlign: 'center' }}>
                            Digite o código enviado por SMS ou Whatsapp.
                          </Alert>

                          {!tokenEnviado ?
                            <>
                              <div className="mb-1">
                                <Label className="form-label">Usuário</Label>
                                <Input name="email" className="form-control" placeholder="CPF OU CNPJ" type="email" onChange={(e) => { setUsuario(e.target.value); }} value={usuario}
                                />

                              </div>

                              <Row className="mb-4" style={{ marginTop: 20 }} >
                                <Col className="text-end" style={{ display: 'flex', justifyContent: 'center' }}>
                                  <button className="btn btn-primary waves-effect waves-light" type="button" onClick={() => { handleEnviarToken(); }} disabled={loading} style={{ width: 160 }}   >
                                    Enviar
                                  </button>
                                </Col>
                              </Row>
                            </>
                            :
                            <>
                              <p style={{ textAlign: 'center' }}>Código de acesso enviado.</p>
                              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                <Col lg={3} style={{ flex: 1 }}>
                                  <input className="form-control" value={codigo1} ref={input1Ref}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                      setCodigo1(e.target.value);
                                    }}
                                    style={{ textAlign: "center" }}
                                    maxLength={1}
                                    type="number"
                                  />
                                </Col>
                                <Col lg={3} style={{ flex: 1 }}>
                                  <input className="form-control" value={codigo2} ref={input2Ref}
                                    onChange={(e) => {
                                      handleInputChange(e);
                                      setCodigo2(e.target.value);
                                    }}
                                    style={{ textAlign: "center" }}
                                    maxLength={1}
                                    type="number"
                                  />
                                </Col>
                                <Col lg={3} style={{ flex: 1 }}>
                                  <input className="form-control" value={codigo3} ref={input3Ref} onChange={(e) => {
                                    handleInputChange(e);
                                    setCodigo3(e.target.value);
                                  }}
                                    style={{ textAlign: "center" }}
                                    maxLength={1}
                                    type="number"
                                  />
                                </Col>
                                <Col lg={3} style={{ flex: 1 }}>
                                  <input className="form-control" value={codigo4} ref={input4Ref} onChange={(e) => {
                                    handleInputChange(e);
                                    setCodigo4(e.target.value);
                                  }}
                                    style={{ textAlign: "center" }}
                                    maxLength={1}
                                    type="number"
                                  />
                                </Col>


                              </Row>
                              <Row>

                                <Col lg={12} style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
                                  <button className="btn btn-default mb-2" disabled={loading ? loading : start ? true : false} onClick={() => { handleEnviarToken() }}  >{loading ? 'Reenviar código' : start ? `00:${time < 10 ? '0' + time : time}` : `Reenviar código`}</button>

                                  <button className="btn btn-primary " style={{ width: 160 }} disabled={loading} onClick={() => { handleValidar(); }}  >
                                    Entrar
                                  </button>
                                </Col>
                              </Row>
                            </>
                          }


                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default CodigoModal;
