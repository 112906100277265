import { MdDownload } from "react-icons/md";
import { MdOutlineEmail as Email } from "react-icons/md";
import { IoLogoWhatsapp as Wpp } from "react-icons/io5";
import ReactToPrint from "react-to-print";
import { BsEyeSlash, BsEyeSlashFill, BsEye } from "react-icons/bs";
import { IoMdPrint } from "react-icons/io";

export const Filter = ({divRef,setDisplay,Compartilha,handleViewTotal,cotacao,viewTotal}) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <a style={{ float: 'right' }} className="btn btn-default" onClick={() => { Compartilha() }} rel="noreferrer" target="_blank">
                <MdDownload size={30} color={'#5c5c5c'} />
            </a>
            <ReactToPrint
                trigger={() => <button className="btn btn-default" style={{ float: 'right' }} onMouseOut={() => {
                    setDisplay ? setDisplay('block') : ''
                }} onMouseOver={() => { setDisplay ? setDisplay('none') : '' }}  >
                    <IoMdPrint size={30} color={'#5c5c5c'} />
                </button>}
                content={() => divRef?.current} />
            <a style={{ float: 'right' }} className="btn btn-default" onClick={() => { Compartilha() }} rel="noreferrer" target="_blank">
                <Email size={30} color={'#5c5c5c'} />
            </a>
            <a style={{ float: 'right' }} className="btn btn-default" href={`https://api.whatsapp.com/send?text=Olá,%20essa%20é%20a%20tabela%20personalizada%20que%20geramos%20para%20você,%20acesse%20esse%20link%20para%20visualizar%20:%20https%3A%2F%2F${window.location.href.replace('https://', '').replace('http://', '')}`} rel="noreferrer" target="_blank">
                <Wpp size={30} color={'#5c5c5c'} />
            </a>
            {cotacao &&
                <a style={{ float: 'right' }} className="btn btn-default" onClick={() => { handleViewTotal() }} rel="noreferrer" target="_blank">
                    {viewTotal ?
                        <BsEye size={30} color={'#5c5c5c'} />
                        :
                        <BsEyeSlash size={30} color={'#5c5c5c'} />
                    }
                </a>
            }

        </div>
    )
}