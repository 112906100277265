import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api/apiOperadoras"
import background from "../../../assets/images/background/progress.png";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { baseUrlApi } from "../../../api/baseUrl";

export default function Logo() {

  const [list, setList] = useState();
  const handleDados = async () => {
    let response = await Api.listagem({ local: 'SITE', ocultar: 1, sortBy: 'ordemgeral', sortOrder: 'asc' });

    if (!response?.error) {
      let list = response?.find
      let operadoras = list.reduce((acc, item) => {
        if (!acc.some((a) => a.arquivo == item?.arquivo)) {
          acc.push(item);
        }
        return acc
      }, [])
      setList(operadoras);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };
  const sliderRef = useRef(null);
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [list]);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredVerMais, setIsHoveredVerMais] = useState(false);
  const handleMouse = () => {
    setIsHoveredVerMais(!isHoveredVerMais);
  };
  const handleMouseEnter = () => {
    setIsHovered(true);

  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const textStyle = {
    textDecoration: isHovered ? 'underline' : 'none',
    cursor: 'pointer'
  };

  const textStyleVerMais = {
    textDecoration: isHoveredVerMais ? 'underline' : 'none',
    cursor: 'pointer'
  };
  return (
    <section
      className="work-progress-three text-white ">
      <div className="container" style={{ textAlign: 'center', marginTop: 25, marginBottom: isDesktop ? 30 : 0 }}>

        <a href="/empresas" onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave} style={{ ...textStyle, color: '#092140', textAlign: 'center', fontSize: 22, padding: 8, borderRadius: 10 }}>Empresas<span style={{ color: '#2278F3' }} >Parceiras</span></a>

        <div className="" style={{ marginTop: 8, marginBottom: 12, marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

          <div className=" " style={{ width: '90%' }} >
            <div className="logo-carousel-wrap " >
              <SliderCom settings={settings} selector={sliderRef}>
                {list &&
                  list.map((item) => (
                    <>
                      <div className="logo-item">
                        <a href={`/${item.subdominio}`}>
                          <img src={`${baseUrlApi}/${item?.imagem?.includes('image') ? item?.imagem : `imagens/${item?.imagem}`}`} />
                        </a>
                      </div>
                    </>
                  ))}
              </SliderCom>
            </div>

          </div>

        </div>
        <div style={{ marginTop: 10, marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="" style={{ margin: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onMouseOver={(e) => { e.target.style.color = '#2278f3'; }}
            onMouseOut={(e) => { e.target.style.color = '#ccc'; }}
          >
            <MdArrowBackIos onClick={prevHandler} color="#ccc" size={15} />
          </div>
          <a href={"/empresas"} style={{ marginRight: 50, marginLeft: 50 }} >
            <span onMouseEnter={handleMouse} onMouseLeave={handleMouse} style={{ ...textStyleVerMais, color: '#2278F3', textAlign: 'center', fontSize: 16, padding: 8, borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>Ver mais</span>

          </a>
          <div className="" style={{ margin: '', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            onMouseOver={(e) => { e.target.style.color = '#2278f3'; }}
            onMouseOut={(e) => { e.target.style.color = '#ccc'; }}
          >
            <MdArrowForwardIos onClick={nextHandler} color="#ccc" size={15} />
          </div>
        </div>
      </div>
    </section>
  );
}
