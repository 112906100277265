import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { ExitModal } from "../../components/exitModal";
import { Produtos } from "../../../components/produtos";
export const VisualizarDetalhe = ({ faixas, info, modalVisualizarDetalhe, setModalVisualizarDetalhe, infoDependente, calculoDetalhe, handleClick, selecteds, handleMouseOut, setInfo, handleCarregamento, handleVisualizarCotacoes, loadingDetalhes, id }) => {
  return (
    <Modal id="modal-center3" size="xl" isOpen={modalVisualizarDetalhe} centered={true} style={{ maxWidth: 1200 }}>
      <div className="modal-body" style={{ width: '100%', overflowX: 'auto' }}>
        <Row >
          <ExitModal setModal={setModalVisualizarDetalhe} />
        </Row>
        <Produtos info={info} infoDependente={infoDependente} calculoDetalhe={calculoDetalhe} selecteds={[]} handleClick={handleClick} handleCarregamento={handleCarregamento} loadingDetalhes={loadingDetalhes} setInfo={setInfo} id={id} cotacao={true}  />
      </div>
    </Modal>
  )
}