import React, { useEffect, useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import logoAqui from "../../assets/images/sualogoaqui.png";
import { useCookies } from 'react-cookie';
import { FaTableList } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineDateRange } from "react-icons/md";
import { baseUrlApi, baseUrlApp } from "../../api/baseUrl";
import { getCookie } from "../cookies";

export const Header = ({ calculoDetalhe, index, cotacao, info, tabela, Compartilha, handleViewTotal, setDisplay, viewTotal, divRef }) => {
    const cookie = getCookie('authUser')
    const obj = cookie
    const dataAtual = () => {
        const meses = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril',
            'Maio', 'Junho', 'Julho', 'Agosto',
            'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];
        const data = new Date();
        const mes1 = meses[data.getMonth()];
        const dia = data.getDate();
        const mes = data.getMonth() + 1; // o valor do mês começa em 0, então é necessário adicionar 1
        const ano = data.getFullYear();
        return { data1: `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano}`, data2: `${mes1}/${ano}` }
    }
    function formatarTelefone(telefone) {
        telefone = telefone?.replace(/\D/g, '');
        if (telefone?.length === 11) {
            return telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        } else if (telefone?.length === 10) {
            return telefone?.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        } else {
            return 'Número inválido';
        }
    }
    function aplicarMascaraCelular(numero) {
        numero = numero?.replace(/\D/g, '');
        if (numero?.length === 11) {
            return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else {
            return numero;
        }
    }
    return (
        <div >
            <div className="" style={{ width: '100%', overflowX: 'auto', marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                    <div style={{ width: 250, marginRight: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        {obj?.foto ?
                            <img src={`${baseUrlApi}/imagens/${obj?.foto}`} width={110} alt="" />
                            :
                            <img src={logoAqui} alt="" width='250px' />
                        }
                    </div>
                    {cotacao &&
                        <div style={{ width: 350 }}>
                            <p style={{ marginBottom: 2, fontSize: 15, fontWeight: 100, width: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                                <FaRegUser size={12} style={{ marginRight: 5, marginBottom: 5 }} /> Corretor: {calculoDetalhe?.usuario?.nome}
                            </p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaWhatsapp size={12} style={{ marginRight: 5 }} />{formatarTelefone(calculoDetalhe?.usuario?.usuariosendereco?.celular)}</p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineEmail size={12} style={{ marginRight: 5 }} /> {calculoDetalhe?.usuario?.email}</p>
                        </div>
                    }
                    {cotacao &&
                        <div style={{ width: 400 }} >
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaRegUser size={12} style={{ marginRight: 5 }} /> Cotação: {calculoDetalhe?.id} </p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><IoLocationOutline size={12} style={{ marginRight: 5 }} />{calculoDetalhe?.estado ? `${calculoDetalhe.estado}` : ''}</p>
                            <p style={{ marginBottom: 2, fontSize: 15, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineDateRange size={12} style={{ marginRight: 5 }} /> Gerado em {dataAtual().data1}</p>
                        </div>
                    }

                    {(tabela && obj?.id) &&
                        <div style={{ flex: 1, flexWrap: 'wrap' }}>
                            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaRegUser size={12} style={{ marginRight: 5 }} /> Corretor:{obj?.nome}</p>
                            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaWhatsapp size={12} style={{ marginRight: 5 }} />{aplicarMascaraCelular(obj?.endereco?.celular)}</p>
                            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineEmail size={12} style={{ marginRight: 5 }} />{obj?.email}</p>
                        </div>
                    }
                    {tabela &&
                        <div style={{ flex: 1, flexWrap: 'wrap' }}>
                            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaTableList size={12} style={{ marginRight: 5 }} /> Tabela Nº: {info?.id}</p>
                            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><IoLocationOutline size={12} style={{ marginRight: 5 }} />{info?.estado} </p>
                            <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineDateRange size={12} style={{ marginRight: 5 }} />Gerado em {dataAtual().data1}</p>
                        </div>
                    }
                </div>
                <div style={{ display: 'flex', marginTop: 20, marginBottom: 10, textAlign: 'justify', lineHeight: 1.4 }}>
                    <div style={{ textAlign: 'justify', fontSize: 15 }} >{calculoDetalhe?.cliente ? calculoDetalhe?.cliente : ''} Seguem as simulações dos planos para análise. As condições apresentadas como preços, regras contratuais e comerciais, possuem validade e estão sujeitas à confirmação  pelas operadoras/seguradoras no processo de fechamento dos contratos. Obrigado pela oportunidade.</div>
                </div>
            </div>
        </div>
    )
};
