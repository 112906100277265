import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api/apiBanner"
import vector1 from "../../../assets/images/vector1.png";
import vector2 from "../../../assets/images/vector2.png";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { baseUrlApi } from "../../../api/baseUrl";
import { getRootDomain } from "../../components/getRootDomain";
export default function Hero() {
  const [list, setList] = useState();
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();

  const handleDados = async () => {
    let response = await Api.listagem({ dominio: getRootDomain(window.location.hostname) });
    if (!response?.error) {
      let list = response?.find
      setList(list);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const sliderRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [list]);
  return (
    <section className="hero-section-two bgs-cover overlay" style={{ backgroundColor: '#f6f7f9', height: '100%', paddingTop: 60 }} >
      {isDesktop && <img src={vector1} style={{ float: 'left', marginTop: '-10px', position: 'absolute' }} />}
      <div className="container">
        <div className="row">
          <div className="col-1" style={{ margin: 'auto', float: 'left', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <MdArrowBackIos onClick={prevHandler} color="#ccc" size={20} />
          </div>
          <div className={`col-${!isDesktop ? '12' : '10'} `} style={{ zIndex: 9999, width: '100%' }} >

            <div className="logo-carousel-wrap " >
              <SliderCom selector={sliderRef} settings={settings}>
                {list &&
                  list.map((item, index) => (
                    <>
                      <div className="row" key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: isDesktop ? 15 : '-10px' }}>

                        {index % 2 === 0 ?
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isDesktop ? 'row' : 'column' }} >
                            <div className=" " style={{ display: 'flex', flex: 2, ...(!isDesktop && { paddingTop: 20, paddingBottom: 20 }), justifyContent: 'center', alignContent: "center" }}>

                              <div className="hero-content text-white" style={{ display: 'flex', justifyContent: 'center', alignContent: "center", flexDirection: "column" }}>

                                <p className=" d-block wow fadeInUp delay-0-2s"
                                  style={{ color: '#092140', lineHeight: item.espacamentotiulo }}
                                  dangerouslySetInnerHTML={{ __html: item?.titulo.replace(/font-family:[^;]+;/g, '') }}>
                                </p>
                                <p
                                  style={{ color: '#092140', lineHeight: item.espacamentodescricao }}
                                  className="wow fadeInUp delay-0-4s mt-10"
                                  dangerouslySetInnerHTML={{ __html: item?.descricao.replace(/font-family:[^;]+;/g, '') }}
                                ></p>
                              </div>
                            </div>
                            <div className="" style={{ display: 'flex', flex: 1, alignItems: "flex-end", justifyContent: 'center', }} >

                              {item.video &&
                                <video controls autoPlay
                                  style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff' }} >
                                  <source src={`${baseUrlApi}/video/` + item.video} type="video/mp4" />
                                </video>
                              }
                              {item.imagem &&
                                <img src={`${baseUrlApi}/${item?.imagem?.includes('image') ? item?.imagem : `imagens/${item?.imagem}`}`} width={'94%'} />
                              }
                            </div>
                          </div>

                          :
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isDesktop ? 'row' : 'column', marginTop: 15 }} >
                            <div className="" style={{ display: 'flex', flex: 1, alignItems: "flex-end", justifyContent: 'flex-end', }} >

                              {item.video &&
                                <video controls autoPlay
                                  style={{ width: '450px', height: '390px', objectFit: 'cover', backgroundColor: '#fff' }} >
                                  <source src={`${baseUrlApi}/video/` + item.video} type="video/mp4" />
                                </video>
                              }
                              {item.imagem &&
                                <img src={`${baseUrlApi}/${item?.imagem?.includes('image') ? item?.imagem : `imagens/${item?.imagem}`}`} width={'94%'} />
                              }


                            </div>
                            <div className=" " style={{ display: 'flex', flex: 2, ...(!isDesktop && { paddingTop: 20, paddingBottom: 20 }), justifyContent: 'center', alignContent: "center" }}>

                              <div className="hero-content text-white" style={{ display: 'flex', justifyContent: 'center', alignContent: "center", flexDirection: "column" }}>

                                <p
                                  className=" d-block wow fadeInUp delay-0-2s"
                                  style={{ color: '#092140', lineHeight: item.espacamentotiulo }}
                                  dangerouslySetInnerHTML={{ __html: item?.titulo.replace(/font-family:[^;]+;/g, '') }}
                                >
                                </p>
                                <p
                                  style={{ color: '#092140', lineHeight: item.espacamentodescricao }}
                                  className="wow fadeInUp delay-0-4s mt-10"
                                  dangerouslySetInnerHTML={{ __html: item?.descricao.replace(/font-family:[^;]+;/g, '') }}
                                ></p>
                              </div>
                            </div>

                          </div>

                        }

                      </div>
                    </>
                  ))}
              </SliderCom>
            </div>

          </div>
          <div className="col-1" style={{ margin: 'auto', display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <MdArrowForwardIos onClick={nextHandler} color="#ccc" size={20} />
          </div>
        </div>
      </div>
      {isDesktop && <img src={vector2} style={{ position: 'absolute', right: 0, bottom: 0 }} />}

    </section>
  );
}
