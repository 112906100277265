import { ApiRequestCotacao } from './request';


export const Api = {
  estados: async (dados) => {
    let response = await ApiRequestCotacao('post', '/estados', dados)
    return response.data.option;
  },
  faixa: async () => {
    let response = await ApiRequestCotacao('post', '/faixa')
    return response.data.input;
  },
  faixas: async (dados) => {
    let response = await ApiRequestCotacao('post', '/faixas', dados)
    return response.data.input;
  },
  listagem: async () => {
    let response = await ApiRequestCotacao('post', '/listagem')
    return response.data;
  },
  filtro: async (dados) => {
    let response = await ApiRequestCotacao('post', '/buscar', dados)
    return response.data;
  },
  status: async (dados) => {
    let response = await ApiRequestCotacao('post', '/status', dados)
    return response.data;
  },
  profissoes: async () => {
    let response = await ApiRequestCotacao('post', '/profissoes')
    return response.data;
  },
  areacomercializacao: async () => {
    let response = await ApiRequestCotacao('post', '/areacomercializacao')
    return response.data;
  },
  cadastro: async (dados) => {
    let response = await ApiRequestCotacao('post', '/cadastro', dados)
    return response.data;
  },
  delete: async (dados) => {
    let response = await ApiRequestCotacao('delete', '/delete/' + dados?.id)
    return response.data.list;
  },
  calculo: async (dados) => {
    let response = await ApiRequestCotacao('post', '/calculo', dados)
    return response.data;
  },
  dados: async (dados) => {
    let response = await ApiRequestCotacao('post', '/dados', dados)
    return response.data.list;
  },

  calculoDetalhe: async (dados) => {
    let response = await ApiRequestCotacao('post', '/calculodetalhe', dados)
    return response.data;
  },
  editar: async (dados) => {
    let response = await ApiRequestCotacao('post', '/editar', dados)
    return response.data;
  },
  municipio: async (dados) => {
    let response = await ApiRequestCotacao('post', '/municipios', dados)
    return response.data;
  },
  agendar: async (dados) => {
    let response = await ApiRequestCotacao('post', '/agendar', dados)
    return response.data;
  },
  agendamentos: async (dados) => {
    let response = await ApiRequestCotacao('post', '/agendamentoUnit', dados)
    return response.data;
  },
  excluirAgendamentos: async (dados) => {
    let response = await ApiRequestCotacao('post', '/excluiragendamento', dados)
    return response.data;
  },
  agendamento: async () => {
    let response = await ApiRequestCotacao('post', '/agendamento')
    return response.data;
  },
  tiposOperadoras: async (dados) => {
    let response = await ApiRequestCotacao('post', '/tiposOperadoras', dados)
    return response.data.option;
  },
  compartilhar: async (dados) => {
    let response = await ApiRequestCotacao('post', '/compartilhar', dados)
    return response.data;
  }
};
