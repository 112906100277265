import React, { useEffect, useState } from "react";
import { Col, Label, Modal, Row } from "reactstrap";
import Select from "react-select";
export const Filtros = ({ calculo, setCalculoFiltro, traducaoCobertura, traducaoCoparticipacao, traducaoContratacao, traducaoReembolso, coberturaList, municipioList, coparticipacaoList, acomodacaoList, contratacaoList, reembolsoList, setMunicipio, setCobertura, setCoparticipacao, setAcomodacao, setContratacao, setReembolso, cobertura, municipio, coparticipacao, acomodacao, contratacao, reembolso,calculoDetalhe }) => {

    return (
        <div  >

            <div className="modal-body" style={{ border: 0 }}>
                <Row style={{ paddingRight: 15, paddingLeft: 15 }}>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-10">
                                <Select name="municipio" value={municipio} onChange={(e) => { setMunicipio(e) }} menuPlacement={"bottom"} placeholder="Municípios"
                                    options={municipioList.sort((a, b) => {
                                        return a.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                            .localeCompare(b.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                    })?.map((item) => { return { value: item.id, label: `${calculoDetalhe?.estado[0]}${calculoDetalhe?.estado[1].toUpperCase()} - `+ item?.nome?.replace('*','') } })} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-3">
                                <Select name='cobertura' value={cobertura}
                                    menuPlacement={"bottom"}
                                    onChange={(e) => { setCobertura(e) }}
                                    placeholder="Cobertura"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(coberturaList ? coberturaList.sort((a, b) => {
                                            return a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                .localeCompare(b.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                        }).map((item) => {
                                            const option = traducaoCobertura?.find((fd) => fd.value === item);
                                            return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                    ].filter(Boolean)}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-3">


                                <Select name="coparticipacao" value={coparticipacao}
                                    onChange={(e) => { setCoparticipacao(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Coparticipacao"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(coparticipacaoList ? coparticipacaoList.sort((a, b) => {
                                            return a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                .localeCompare(b.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                        }).map((item) => {
                                            const option = traducaoCoparticipacao?.find((fd) => fd.value === item);
                                            return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                    ].filter(Boolean)}
                                />


                            </div>
                        </div>
                    </Col>

                    <Col xs={6} lg={6} >
                        <div>
                            <div className="mb-3">
                                <Select name="acomodacao" value={acomodacao}
                                    onChange={(e) => { setAcomodacao(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Acomodação"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(acomodacaoList ? acomodacaoList.sort((a, b) => {
                                            return a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                .localeCompare(b.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                        }).map((item) => {
                                            const option = { label: item, value: item }
                                            return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                    ].filter(Boolean)}




                                />


                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-3">



                                <Select name="contratacao" value={contratacao}
                                    onChange={(e) => { setContratacao(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Contratação"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(contratacaoList ? contratacaoList.sort((a, b) => {
                                            return b.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                .localeCompare(a.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                        }).map((item) => {
                                            const option = traducaoContratacao?.find((fd) => fd.value === item);
                                            return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                    ].filter(Boolean)} />


                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6} >
                        <div>
                            <div className="mb-3">

                                <Select name="possueReembolso" value={reembolso}
                                    onChange={(e) => { setReembolso(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Reembolso"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(reembolsoList ? reembolsoList.sort((a, b) => {
                                            return b.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                                .localeCompare(a.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                        }).map((item) => {
                                            const option = traducaoReembolso?.find((fd) => fd.value === item);
                                            return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                    ].filter(Boolean)}
                                />
                            </div>
                        </div>
                    </Col>




                </Row>

            </div>
            {/* <div className="modal-footer" style={{ border: 0 }}>
                <button className="btn btn-primary" onClick={handleFiltro}  >Filtrar</button>
            </div> */}
        </div>



    )
}